<template>
  <div>
    <div v-if="!loadingBroadcast" class="d-block" style="margin-bottom: 200px;">
      <CreateSMS v-if="type === 'sms'" ref="smsComponent" :object="broadcast"></CreateSMS>
      <CreateEmail v-else ref="emailComponent" :object="broadcast"></CreateEmail>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import CreateEmail from './CreateEmail'
import CreateSMS from './CreateSMS'
import moment from 'moment-timezone'

export default {
  components: {
    CreateEmail,
    CreateSMS
  },

  data() {
    return {
      loadingBroadcast: true,
      type: this.$route.query.type ? this.$route.query.type : 'sms',
      broadcast: null,
      listKeywords: [],
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.getBroadcast()
    } else {
      this.loadingBroadcast = false
    }
  },

  methods: {
    getBroadcast() {
      this.loadingBroadcast = true

      this.$store
        .dispatch('broadcast/find', this.$route.params.id)
        .then((data) => {
          this.type = data.provider
          if (data.status === 'scheduled') {
            data.send_type = 'later'
            if (!data.scheduled_at)
            {
              data.scheduled_at = moment();
              data.scheduled_at.set({minute:0,second:0})
            }
          } if (data.status === 'draft') {
            data.send_type = 'now'
          } else {
            if (data.scheduled_at)
            {
              data.send_type = 'later'
            } else {
              data.send_type = 'now'
            }
          }

          data.date = moment(data.scheduled_at).tz(data.timezone || this.user.business.timezone).format('YYYY-MM-DD')
          data.time = moment(data.scheduled_at).tz(data.timezone || this.user.business.timezone).format('HH:mm:00')
          this.broadcast = JSON.parse(JSON.stringify(data))
          this.broadcast.exclude_keywords = this.listKeywords.filter(c => this.broadcast.exclude_keywords.includes(c.id))

          this.loadingBroadcast = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.broadcasts.index' })
          this.loadingBroadcast = false
        })
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords.map(c => c.name)
          this.listKeywords = keywords
          this.getBroadcast()
        })
        .catch(() => {
        })
    },
  },

  beforeRouteLeave (to, from , next) {
    if (this.$refs.smsComponent) {
      this.$refs.smsComponent.beforeRouteLeave(to, from, next)
    } else {
      this.$refs.emailComponent.beforeRouteLeave(to, from, next)
    }
  }
}
</script>