<template>
  <div class="create-broadcast">
    <div class="create-broadcast-wrapper">
      <div class="card builder">
        <div class="card-body">
          <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="broadcastForm">
            <form v-if="broadcast" class="authentication-form" @submit.prevent="handleSubmit(onSendBroadcast)">
              <p v-if="isResend" class="text-primary font-weight-bold text-left"><i><span v-if="resendType === 1">Send to
                    new contacts (since this campaign was originally sent)</span><span v-else>Send to contacts who have
                    not read/opened this campaign</span></i></p>
              <TextInput v-model="broadcast.name" label="Name" rules="required" name="name" />
              <ValidationProvider v-slot="{ failed, errors }" name="keyword">
                <div class="form-group">
                  <div v-if="showKeywords" class="d-block">
                    <label>Keywords</label>
                    <multiselect v-model="broadcast.keywords" :options="keywords" :multiple="true"
                      :class="{ 'is-invalid': failed }" placeholder="Select keywords" :disabled="isResend"></multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>

                  <div class="mt-2 pl-2">
                    <b-spinner v-if="loadingCredit" small></b-spinner>
                    <div v-if="!loadingCredit && messageCredit">
                      <div v-if="isEmail">
                        <div v-if="messageCredit && messageCredit.credit">
                          <span class="text-success">{{ messageCredit.credit }} contacts are available.<br /></span>
                        </div>
                        <div v-else>
                          <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="msgCreditUsed">
                          <span v-if="messageCredit.creditAvailable" :class="{
                            'text-danger': messageCredit.creditAvailable < msgCreditUsed,
                            'text-success': messageCredit.creditAvailable >= msgCreditUsed,
                          }">Credits Available: {{ messageCredit.creditAvailable }}<br /></span>
                          <span>Credit Required For This Broadcast: {{ msgCreditUsed }}<br /></span>
                          <span v-if="messageCredit.creditAvailable && messageCredit.creditAvailable < msgCreditUsed"
                            class="text-danger">ALERT: There are not enough credits available to run a broadcast. Contact
                            <a :href="'mailto:' + agencyEmail" target="_blank"><b class="text-danger">{{ agencyEmail
                            }}</b></a> to upgrade.</span>
                        </div>
                        <div v-else>
                          <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="showExcludeKeywords" class="form-group">
                  <label>Exclude these people from the broadcast</label>
                  <multiselect v-model="broadcast.exclude_keywords" :options="listKeywords" :multiple="true"
                    :class="{ 'is-invalid': failed }" placeholder="Select keywords" label="name" track-by="id"
                    :disabled="isResend"></multiselect>
                </div>
              </ValidationProvider>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <b-input-group-append>
                      <b-button style="border-radius:0.3rem 0 0 0.3rem;"
                        :variant="broadcast.send_type === 'now' ? 'primary' : 'outline-dark'"
                        @click="chooseSentAt('now')">Send Now</b-button>
                      <b-button style="border-left: none;border-radius:0 0.3rem 0.3rem 0;"
                        :variant="broadcast.send_type === 'later' ? 'primary' : 'outline-dark'"
                        @click="chooseSentAt('later')">Send Later</b-button>
                    </b-input-group-append>
                  </div>
                  <div v-if="broadcast.send_type === 'later'" class="col-md-12">
                    <div class="row">
                      <div class="col-6">
                        <flat-pickr v-model="broadcast.date" :config="dateTimePicker" class="form-control"
                          placeholder="Pick a date"></flat-pickr>
                      </div>
                      <div class="col-6">
                        <TimePicker v-model="broadcast.time" :night-off="true" />
                      </div>
                      <div class="col-md-12 mt-3">
                        <SelectInput v-model="broadcast.timezone" :options="timezones" label="Timezone" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isEmail">
                <div v-if="showEmailOptions" class="form-group">
                  <label>Email account <span v-b-tooltip.hover class="cursor-pointer"
                      title="You can leave this blank to use system default email client."><i
                        class="uil uil-question-circle"></i></span></label>
                  <multiselect v-model="broadcast.options.sendgrid_integration_user" :options="emailOptions"
                    :multiple="false" :allow-empty="false" track-by="id" placeholder="Select Account" label="name">
                  </multiselect>
                </div>
                <div class="form-group">
                  <MergeFieldsGroup :text-field="$refs.message" :show-redemption="true" :hide-expire-date="true"
                    @selected="appendField" />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <TextInput v-model="broadcast.options.from_name" label="From Name" name="fromName"
                      :disabled="isResend"></TextInput>
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="broadcast.options.from_email" label="From Email" name="fromEmail"
                      :disabled="isResend"></TextInput>
                  </div>
                </div>
                <TextInput v-model="broadcast.subject" label="Subject" name="subject" rules="required"></TextInput>
                <TextEditorAction v-model="broadcast.message" :object="broadcast" rules="required"></TextEditorAction>
              </div>
              <div v-else>
                <div class="form-group">
                  <MergeFieldsGroup :text-field="$refs.message" :show-redemption="true" :hide-expire-date="true"
                    @selected="appendField" />
                </div>

                <label for="">Message
                  <feather v-b-tooltip.hover type="help-circle"
                    style="vertical-align:bottom;margin-left:10px;cursor:pointer;" :title="previewTooltip" />
                </label>
                <TextAreaAction ref="message" v-model="broadcast.message" :sms-length="true" rules="required"
                  :object="broadcast" :add-optout="true" name="message" :disabled="isResend" />
              </div>

              <div class="d-flex justify-content-between">
                <b-form-group>
                  <b-button variant="primary" :disabled="invalid" type="button" class="mr-2" @click="onClickTest">
                    <b-spinner v-if="loadingSend" small />
                    <span v-else><i class="uil uil-text"></i> Send Test {{ isEmail ? 'Email' : 'Message' }}</span>
                  </b-button>

                  <b-button v-if="!isResend" variant="primary" :disabled="invalid" type="button" class="mr-2"
                    @click="handleSaveAsDraft">
                    <b-spinner v-if="loadingSend" small />
                    <span v-else><i class="uil uil-document"></i> Save As Draft</span>
                  </b-button>
                  <b-button variant="primary" :disabled="!newBroadcastValid || (invalid)" type="submit">
                    <b-spinner v-if="loadingSend" small />
                    <span v-else><i class="uil uil-upload-alt"></i> Send</span>
                  </b-button>
                </b-form-group>
                <b-form-group>
                  <b-button variant="warning" @click="onBack">
                    <span><i class="uil uil-arrow-left"></i> Back</span>
                  </b-button>
                </b-form-group>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="preview">
        <div v-if="showPreview" class="preview-inner">
          <!-- eslint-disable vue/no-v-html -->
          <div class="preview-block" v-html="previewContent"></div>
          <!--eslint-enable-->
          <span class="d-block help-icon">
            <feather v-b-tooltip.hover type="help-circle" style="cursor:pointer;" :title="previewTooltip" />
          </span>
        </div>
      </div>
    </div>
    <b-modal v-model="isOpenModalTest" title="Test Broadcast">
      <template v-if="isEmail">
        <p>Please Input Email Address to Send Test Broadcast.</p>
        <TextInput v-model="testNumber" name="testEmail" rules="required|email" label="Email" />
      </template>
      <template v-else>
        <p>Please Input Phone Number to Send Test Broadcast.</p>
        <PhoneInput v-model="testNumber" name="Phone Number" label="Phone Number" rules="required|phone" />
      </template>
      <template v-slot:modal-footer>
        <b-button variant="light" class="mr-2" @click="isOpenModalTest = false">Close</b-button>
        <b-button :disabled="loadingSend || !testNumber" variant="primary" @click="handleTest">
          <b-spinner v-if="loadingSend" class="align-middle" small></b-spinner>
          <span v-else>Send</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isOpenModalConfirm" title="Confirmation">
      <p>When sending out a broadcast {{ isEmail ? 'emails' : 'messages' }}, it is important that you send these messages
        during the day between <b class="text-primary">8am</b> and <b class="text-primary">9pm</b> during the recipients
        timezone. <br />If you aren't aware of their time zone, we suggest sending it in the middle of the day.</p>
      <p>Click the button below to confirm you want to send or schedule this messages at this time.</p>
      <template v-slot:modal-footer>
        <b-button variant="light" class="mr-2" @click="isOpenModalConfirm = false">Close</b-button>
        <b-button :disabled="loadingSend" variant="primary" @click="sendBroadcast">
          <b-spinner v-if="loadingSend" class="align-middle" small></b-spinner>
          <span v-else>{{ confirmLabel }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'

export default {
  components: {
    Multiselect,
  },

  props: {
    object: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      defaultBroadcast: {
        message: '',
        keywords: [],
        send_type: 'now',
        name: null,
        date: moment().format('YYYY-MM-DD'),
        time: '08:00',
        timezone: moment.tz.guess(),
        images: [],
        exclude_keywords: [],
        provider: 'sms',
        options: {},
      },
      loadingSend: false,
      loadingKeyword: false,
      loadingCredit: false,
      messageCredit: null,
      broadcast: {},
      keywords: [],
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      isOpenModalTest: false,
      isOpenModalConfirm: false,
      testNumber: null,
      listKeywords: [],
      emailOptions: [],
      isResend: false,
      resendType: 1,
      constBroadcast: null,
    }
  },

  computed: {
    showKeywords() {
      return !this.isResend || (this.broadcast.keywords && this.broadcast.keywords.length)
    },

    showExcludeKeywords() {
      return !this.isResend || (this.broadcast.exclude_keywords && this.broadcast.exclude_keywords.length)
    },

    showPreview() {
      return this.broadcast.provider === 'sms'
    },

    previewContent() {
      let res = this.broadcast.message
      res = res.split('\n').join('<br>');
      res += '<br/>Reply STOP to opt out'
      if (this.business.organizational_prefix) {
        res = this.business.organizational_prefix + ': ' + res
      }

      let mergeFields = {
        'company': this.business.name,
        'business_phone': this.formatNational(this.business.owner.phone_number),
        'marketing_number': this.formatNational(this.business.phone_number),
      }

      Object.keys(mergeFields).forEach(key => {
        res = res.replaceAll(`[${key}]`, mergeFields[key])
      });

      return res
    },

    previewTooltip() {
      return 'To change the prefix of the sms message go to Settings > SMS Registration. You can not change the opt out message.';
    },

    showEmailOptions() {
      return this.emailOptions && this.emailOptions.length > 1
    },

    isEmail() {
      return this.broadcast.provider === 'email'
    },

    confirmLabel() {
      return this.broadcast.send_type === "now" ? "Send Now" : "Schedule";
    },

    newBroadcastValid() {
      if (!this.msgCreditUsed) return false

      if (this.broadcast.send_type === 'now') {
        return !this.loadingSend;
      } else {
        return (
          this.broadcast.date &&
          this.broadcast.time &&
          !this.loadingSend
        );
      }
    },
    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    business() {
      return this.$store.getters['auth/business']
    },

    userTimezone() {
      return this.business ? this.business.timezone : moment.tz.guess()
    },

    agencyEmail() {
      return this.business && this.business.agency && this.business.agency.email;
    },

    msgCount() {
      if (this.broadcast.images && this.broadcast.images.length)
        return 3;
      return Math.ceil((this.broadcast.message.length + 21) / 160)
    },

    msgCreditUsed() {
      if (this.messageCredit && this.messageCredit.credit)
        return this.messageCredit.credit * this.msgCount
      return 0
    },
  },

  watch: {
    'broadcast.keywords': function (val) {
      this.broadcast && this.getMessageCredit()
    },
    'broadcast.exclude_keywords': function (val) {
      this.broadcast && this.getMessageCredit()
    },
  },

  mounted() {
    this.isResend = this.$route.name === 'business.broadcasts.resend'
    if (this.isResend) {
      this.resendType = this.$route.query.type
    }

    this.getKeywords()
    this.getEmailOptions()
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider } })
    },
    getEmailOptions() {
      this.$store.dispatch('integration/selectOptions')
        .then(options => {
          this.emailOptions = options
          if (options && options.length === 1) {
            this.broadcast.options.integration_user = options[0]
          }
        })
        .catch(() => { })
    },

    initBroadcast() {
      if (this.$route.params.id) {
        if (this.object) {
          this.broadcast = JSON.parse(JSON.stringify(this.object))
        } else {
          this.getBroadcast(this.$route.params.id)
        }
      } else {
        this.broadcast = Vue.util.extend({}, this.defaultBroadcast);
        this.broadcast.timezone = this.userTimezone
        this.broadcast.provider = this.$route.query.type ? this.$route.query.type : 'sms'
        this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
      }
    },

    getBroadcast(id) {
      this.loadingBroadcast = true

      this.$store
        .dispatch('broadcast/find', id)
        .then((broadcast) => {
          const param = {
            id: broadcast.id,
            message: broadcast.message,
            subject: broadcast.subject,
            provider: broadcast.provider,
            keywords: broadcast.keywords,
            exclude_keywords: broadcast.exclude_keywords,
            send_type: broadcast.scheduled_at ? 'later' : 'now',
            name: broadcast.name,
            date: moment(broadcast.send_at).tz(this.userTimezone).format('YYYY-MM-DD'),
            time: moment(broadcast.send_at).tz(this.userTimezone).format('HH:00:00'),
            timezone: this.userTimezone,
            images: broadcast.images,
            options: broadcast.options,
          }

          this.broadcast = JSON.parse(JSON.stringify(param))
          this.broadcast.exclude_keywords = this.listKeywords.filter(c => this.broadcast.exclude_keywords.includes(c.id))
          this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
          this.loadingBroadcast = false

        })
        .catch(() => {
          this.$router.push({ name: 'business.broadcasts.index' })
          this.loadingBroadcast = false
        })
    },
    onClickTest() {
      this.testNumber = null
      this.isOpenModalTest = true
    },
    handleTest() {
      this.loadingSend = true;
      const param = Vue.util.extend({}, this.broadcast);
      param.number = this.testNumber
      this.$store.dispatch('broadcast/sentTest', param).then((res) => {
        this.isOpenModalTest = false;
        this.loadingSend = false;
      })
        .catch(() => {
          this.loadingSend = false;
        });;
    },
    onSendBroadcast() {
      this.isOpenModalConfirm = true
    },
    sendBroadcast() {
      this.loadingSend = true

      if (this.broadcast.send_type === 'later') {
        this.broadcast.scheduled_at = moment.tz(this.broadcast.date + ' ' + this.broadcast.time, "YYYY-MM-DD HH:mm", this.broadcast.timezone || this.business.timezone).utc().format();
      } else {
        delete this.broadcast.scheduled_at
      }

      this.broadcast.options['prev_id'] = this.$route.params.id
      this.broadcast.options['is_resend'] = this.isResend
      this.broadcast.options['resend_type'] = this.resendType

      this.$store
        .dispatch('broadcast/create', this.broadcast)
        .then(() => {
          this.loadingSend = false
          this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
          this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider } })
        })
        .catch(() => {
          this.loadingSend = false
        })
    },

    getKeywords() {
      this.loadingKeyword = true

      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords.map(c => c.name)
          this.listKeywords = keywords
          this.initBroadcast()
          this.loadingKeyword = false
        })
        .catch(() => {
          this.loadingKeyword = false
        })
    },

    getMessageCredit() {
      this.loadingCredit = true

      const param = {
        keywords: this.broadcast.keywords,
        excludes: this.broadcast.exclude_keywords,
        provider: this.broadcast.provider,
        isResend: this.isResend,
        resendType: this.resendType,
        id: this.broadcast.id,
      }

      this.$store
        .dispatch('broadcast/messageCredit', param)
        .then((messageCredit) => {
          this.messageCredit = messageCredit
          this.loadingCredit = false
        })
        .catch(() => {
          this.loadingCredit = false
        })
    },

    chooseSentAt(sendType) {
      this.broadcast.send_type = sendType
    },

    handleSaveAsDraft() {
      this.broadcast.save_draft = true
      this.sendBroadcast()
    },

    appendField(field) {
      document.execCommand('insertText', false, field)
    },

    beforeRouteLeave(to, from, next) {
      let diff = this.difference(this.broadcast, this.constBroadcast)

      if (Object.keys(diff).length) {
        const answer = window.confirm('Are you sure you want to leave without saving?')
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    }
  },
}
</script>

<style lang="scss">
.create-broadcast {
  .flatpickr-input {
    background: #FFF !important;
  }

  .create-broadcast-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .builder {
      flex: 0 0 600px;
      max-width: 600px;
    }

    .preview {
      flex: 1 1 auto;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      max-width: 1000px;

      .preview-inner {
        position: fixed;
        top: 120px;
        height: 800px;
        width: 320px;
        background-image: url('~~~@assets/images/mobile-screen.png');
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 80px 0px 80px 45px;
        text-align: left;
        font-size: 13px;

        .help-icon {
          position: absolute;
          right: -20px;
          top: 40px;
        }

        .preview-block {
          position: relative;
          display: inline-block;
          padding: 10px 10px;
          color: #4b4b5a;
          background: #e2e7f1;
          border-radius: 1rem;
          border-bottom-left-radius: 0;
          cursor: pointer;
          max-width: 85%;

          &::after {
            position: absolute;
            bottom: 0;
            right: 99%;
            width: 0;
            height: 0;
            margin-left: -1px;
            pointer-events: none;
            content: ' ';
            border: solid transparent;
            border-width: 6px;
            border-bottom-color: #e2e7f1;
            border-right-color: #e2e7f1;
          }
        }
      }
    }
  }
}
</style>